export default {
  'home': 'Home',
  'merchants_management': 'Merchants Management',
  'templates_management': 'Templates Management',
  'store_migration': 'SKU Migration',
  'google_integration': 'Google Integration',
  'theme_app_extensions_instruction': 'Theme App Extensions',
  'help_center': 'Help Center',
  'agencies_management': 'User Management',
  'appeal_record_management': 'Appeal Records Management',
  'campaign': 'Google Ads Campaign',
  'review': 'Reviews Management',
  'reporting': 'Reporting',
  'metaobject_swatch': 'Color Swatch',
  'metaobject_size_table': 'Size Table',
  'store_settings_and_migration': 'Store migration and setup',
  'arit': 'Website Check',
  'reporting_data': 'Marketing Report',
  'reporting_suggestion': 'Optimization Projects',
  'optional_menu': 'Optional',
  'gads': 'Google Ads',
  'googleUserLists': 'Customer list',
  'blogs': 'Blog articles',
  'tamar_ai_accounts': 'Amazon Store Selection',
  'pmax_campaign_guide': 'Pmax Campaign Guide',
  'blog_guide': 'Blog Guide',
  'campaign_guide': 'Campaign Guide',
  'shopping_campaign_guide': 'Shopping Campaign Guide',
  'search_campaign_guide': 'Search Campaign Guide',
  'tamar_ai_account_guide': 'Amazon Store Selection Guide',
  'poi_insights_report': 'Non-Amazon Industry Report',
  'pre_sale_toolbox': 'Pre-sale Toolbox',
  'pre_sale_toolbox_guide': 'Pre-sale Toolbox Guide',
  'uiux_analytics_report': 'Website Experience Report',
};
