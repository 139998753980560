export default {
  title: 'Amazon Store Selection',
  button_auth: 'Authorize',
  auth_success: 'Authorized',
  fields: {
    customer_name: 'Customer Business Name',
    email: 'Customer Email',
    phone_number: 'Phone Number',
    report_status: 'Report Status',
    account_list: {
      label: 'Account List',
      fields: {
        account_name: 'Account Name',
        region: 'Region',
        seller_id: 'Seller ID',
        store_list: {
          label: 'Store List',
          fields: {
            account_name: 'Account Name',
            region: 'Region',
            country: 'Country',
            is_ad_api_authorized: 'Ad Account Authorized',
            is_sp_api_authorized: 'Seller Account Authorized',
            ad_oauth_url: 'Ad API Authorization URL',
            sp_oauth_url: 'SP API Authorization URL',
            store_id: 'Store ID',
          },
        },
      },
    },
    uiux_analytics_report_status: 'Report Status',
    ga4_accounts: 'GA4 Account Data'
  },
  form: {
    title: 'Create Amazon Store Selection Account',
  },
  show: {
    title: 'Amazon Store Selection Details',
  },
  add_store: {
    title: 'Add Store',
    account_name: 'Account Name',
    store_type: 'Store Type',
    region: 'Region',
    redirect_url: 'Redirect URL',
  },
  actions: {
    generate_report: 'Generate Report',
    regenerate_report: 'Regenerate Report',
    sync_account_list: 'Sync Authorization Status',
    generate_access_token: 'Get Merchant Authorization Link',
    download_report: 'Download Report',
  },
  add_ga4_store: {
    title: 'Add GA4 Store',
  },
  ga4: {
    account_id: 'Account ID',
    account_name: 'Account Name',
    google_account_email: 'Google Account Email',
    properties: 'Properties',
    property_id: 'Media Resource ID',
    property_name: 'Media Resource Name',
    website_url: 'Website URL',
    status: 'Status',
    no_accounts: 'No GA4 Account Data',
    auth_button: 'GA4 Authorization',
    auth_success: 'GA4 Authorized',
    status_authorized: 'Authorized',
    status_unauthorized: 'Unauthorized',
    revoke_confirm: 'Are you sure you want to revoke the GA4 store authorization?',
    revoke: 'Revoke Authorization',
  }
};
