import { authStore } from '@/stores';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { INSTRUCTION_TYPE } from '@/constants';
import { MenuType, filterMenus } from './use-merchant-menu';
import { useInstructionAbility } from './use-instruction-ability';


export function useMainMenu() {
  const { t } = useI18n();
  const { ability } = useInstructionAbility();

  const menus = computed(() => {
    const userAbility = authStore.user.abilities;
    const menuDef: MenuType[] = [
      {
        title: t('menu.merchants_management'),
        icon: 'user',
        route: { name: 'merchants.index' },
        if: () => userAbility?.read_merchant,
      },
      {
        title: t('menu.agencies_management'),
        icon: 'UserFilled',
        route: { name: 'agencies.index' },
        if: () => userAbility?.read_agency,
      },
      {
        title: t('menu.templates_management'),
        icon: 'document',
        route: { name: 'policy-templates.index' },
        if: () => userAbility?.create_policy_template,
      },
      {
        title: t('menu.appeal_record_management'),
        icon: 'Warning',
        route: { name: 'appeal-records' },
        if: () => userAbility?.read_appeal_record,
      },
      {
        title: t('menu.help_center'),
        icon: 'QuestionFilled',
        if: () => true,
        children: [
          {
            title: t('menu.help_center'),
            route: { name: 'help-center', params: { type: authStore.user.project === 'ftz' ? INSTRUCTION_TYPE.ftz_help_center : INSTRUCTION_TYPE.help_center } },
            icon: 'Document',
            if: () => true,
          },
          {
            title: t('menu.blog_guide'),
            route: { name: 'help-center', params: { type: 'blog_guide' } },
            icon: 'Document',
            if: () => ability.value.blog_guide,
          },
          {
            title: t('menu.campaign_guide'),
            icon: 'Document',
            children: [
              {
                title: t('menu.pmax_campaign_guide'),
                route: { name: 'help-center', params: { type: 'pmax_campaign_guide' } },
                icon: 'Document',
                if: () => ability.value.pmax_campaign_guide,
              },
              {
                title: t('menu.shopping_campaign_guide'),
                route: { name: 'help-center', params: { type: 'shopping_campaign_guide' } },
                icon: 'Document',
                if: () => ability.value.shopping_campaign_guide,
              },
              {
                title: t('menu.search_campaign_guide'),
                route: { name: 'help-center', params: { type: 'search_campaign_guide' } },
                icon: 'Document',
                if: () => ability.value.search_campaign_guide,
              },
            ]
          },
          {
            title: t('menu.tamar_ai_account_guide'),
            route: { name: 'help-center', params: { type: INSTRUCTION_TYPE.amazon_product_choice_guide } },
            icon: 'Document',
            if: () => ability.value.amazon_product_choice_guide,
          },
          {
            title: t('menu.pre_sale_toolbox_guide'),
            icon: 'Document',
            children: [
              {
                title: t('preSaleBox.categoryInsightsReport.title'),
                route: { name: 'help-center', params: { type: INSTRUCTION_TYPE.subcategory_insight_report_guide } },
                icon: 'Document',
                if: () => userAbility?.read_subcategory_insight_report,
              }
            ]
          },
          {
            title: t('menu.uiux_analytics_report'),
            route: { name: 'help-center', params: { type: INSTRUCTION_TYPE.uiux_analytics_report_guide } },
            icon: 'Document',
            if: () => ability?.value.uiux_analytics_report_guide,
          }
        ],
      },
      {
        title: t('menu.pre_sale_toolbox'),
        icon: 'Setting',
        if: () => userAbility?.read_subcategory_insight_report,
        children: [
          {
            title: t('preSaleBox.categoryInsightsReport.title'),
            route: { name: 'category-insights-reports' },
            icon: 'Document',
            if: () => userAbility?.read_subcategory_insight_report,
          }
        ]
      }
    ];
    return filterMenus(menuDef);
  });

  return {
    menus
  };
}
