import { INSTRUCTION_TYPE } from '@/constants';
import { authStore } from '@/stores';
import { computed } from 'vue';

export function useInstructionAbility() {
  const ability = computed<Record<keyof typeof INSTRUCTION_TYPE, boolean | undefined>>(() => {
    const userAbility = authStore.user.abilities;
    const project = authStore.user.project;

    return {
      create_shopify_account: true,
      create_shoplazza_account: true,
      add_shopify_payment_method: true,
      add_shoplazza_payment_method: true,
      google_auth: true,
      get_google_analytics: true,
      install_google_channel: true,
      add_google_conversion_code: true,
      add_shoplazza_policy: true,
      shoplazza_google_integrate: true,
      product_options_picker_app_embed_block: true,
      help_center: true,
      appeal_letter_flow_guide: true,
      ftz_help_center: true,
      // TODO: 没有权限相关配置，只能先这样判断了
      pmax_campaign_guide: project === 'ftz',
      blog_guide: userAbility?.create_blog,
      shopping_campaign_guide: project === 'ftz',
      search_campaign_guide: project === 'ftz',
      amazon_product_choice_guide: project === 'ftz',
      subcategory_insight_report_guide: userAbility?.read_subcategory_insight_report,
      uiux_analytics_report_guide: project === 'ftz',
    };
  });

  return {
    ability,
  };
}
