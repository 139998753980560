import type { RouteRecordRaw } from 'vue-router';
import { RouterView } from 'vue-router';
import Index from './index.vue';
import New from './new.vue';
import AuthSuccess from './auth-success.vue';

const route: RouteRecordRaw = {
  path: 'uiux-analytics-report',
  component: RouterView,
  children: [
    {
      path: '',
      component: Index,
      name: 'uiux-analytics-report.index',
    },
    {
      path: 'new',
      component: New,
      name: 'uiux-analytics-report.new',
    },
    {
      path: 'auth-success',
      component: AuthSuccess,
      name: 'uiux-analytics-report.auth-success',
    }
  ]
};

export const frontendRoute: RouteRecordRaw = {
  ...route,
  children: route.children?.map(child => ({
    ...child,
    name: `frontend.${child.name as string}`,
  }))
};

export default route;
