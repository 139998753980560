import { MerchantType } from '@/models/merchant';
import { GOOGLE_INTEGRATE_STATUS } from '@/constants';
import { RouteLocationRaw } from 'vue-router';
import _ from 'lodash';
import i18n from '@/i18n';

const t = i18n.global.t;

interface IStep {
  title: string;
  route: RouteLocationRaw;
  status: Array<keyof typeof GOOGLE_INTEGRATE_STATUS>
}

export function getGoogleIntegrationSteps(merchant: MerchantType): IStep[] {
  if (merchant.migrate_to === 'shoplazza') {
    return [
      {
        title: '',
        route: { name: 'shoplazza-google-integration' },
        status: ['pending', 'completed']
      }
    ];
  }

  return [
    {
      title: t('google_integration.install_google_app'),
      route: { name: 'google-integration.install-app' },
      status: ['pending']
    },
    {
      title: t('google_integration.checkout_page_tracking'),
      route: { name: 'google-integration.checkout-page-tracking' },
      status: ['added_google_channel', 'completed']
    },
  ];
}

export function getActiveGoogleIntegrationStepIndex(merchant: MerchantType) {
  return _.findLastIndex(
    getGoogleIntegrationSteps(merchant),
    step => step.status.includes(merchant.google_integrate_status)
  );
}

export function getActiveGoogleIntegrationStep(merchant: MerchantType) {
  return _.findLast(
    getGoogleIntegrationSteps(merchant),
    step => step.status.includes(merchant.google_integrate_status)
  );
}

export function getActiveGoogleIntegrationProgress(merchant: MerchantType) {
  if (merchant.migrate_to === 'shoplazza') {
    return merchant.google_integrate_status === 'completed' ? 100 : 0;
  }
  if (merchant.google_integrate_status === 'completed') {
    return 100;
  }
  return Math.floor(getActiveGoogleIntegrationStepIndex(merchant) / getGoogleIntegrationSteps(merchant).length * 100);
}
