import { MerchantType } from '@/models/merchant';
import { Ref, computed } from 'vue';
import { authStore } from '@/stores';
import { useI18n } from 'vue-i18n';
import { type RouteLocationRaw } from 'vue-router';
import { getStoreMigrationProgress, getActiveGoogleIntegrationProgress } from '@/utils';

export interface MenuType {
  title: string;
  index?: string;
  icon?: string;
  route?: RouteLocationRaw;
  children?: Array<MenuType>;
  if?: () => boolean | undefined;
  progress?: number;
}

export function filterMenu(menu: MenuType): MenuType | null {
  if (menu.children?.length) {
    const children = menu
      .children
      .map(filterMenu).filter(Boolean) as MenuType[];
    return children.length ? { ...menu, children } : null;
  }
  return menu.if?.() ? menu : null;
}

export function filterMenus(menus: MenuType[]): MenuType[] {
  return menus.map(filterMenu).filter(Boolean) as MenuType[];
}

export function useMerchantMenu(merchant: Readonly<Ref<MerchantType>>) {
  const { t } = useI18n();

  const menus = computed(() => {
    const userAbility = authStore.user.abilities;

    const menuDef: MenuType[] = [
      {
        title: t('menu.store_settings_and_migration'),
        route: { name: 'store-migration' },
        icon: 'setting',
        if: () => merchant.value.abilities?.migrate,
        children: [
          {
            title: t('menu.store_migration'),
            route: { name: 'store-migration', params: { id: merchant.value.id } },
            icon: 'shop',
            if: () => merchant.value.abilities?.migrate,
            progress: getStoreMigrationProgress(merchant.value),
          },
          {
            icon: 'ChatLineSquare',
            title: t('menu.review'),
            route: { name: 'reviews', params: { id: merchant.value.id } },
            if: () => merchant.value.abilities?.read_review,
          },
          {
            icon: 'Document',
            title: t('menu.blogs'),
            route: { name: 'blogs.index', params: { id: merchant.value.id } },
            if: () => authStore.user.abilities?.read_blog,
          },
          {
            icon: 'Edit',
            title: t('menu.optional_menu'),
            children: [
              {
                icon: 'document',
                title: t('menu.metaobject_swatch'),
                route: { name: 'metaobject.swatch', params: { id: merchant.value.id } },
                if: () => merchant.value.abilities?.read_metaobject,
              },
              {
                icon: 'document',
                title: t('menu.metaobject_size_table'),
                route: { name: 'metaobject.sizeTable', params: { id: merchant.value.id } },
                if: () => merchant.value.abilities?.read_metaobject,
              }
            ]
          },
        ]
      },
      {
        title: t('menu.arit'),
        icon: 'MagicStick',
        route: { name: 'arits', params: { id: merchant.value.id } },
        if: () => userAbility?.read_arit_task,
      },
      {
        title: t('menu.google_integration'),
        route: { name: 'google-integration', params: { id: merchant.value.id } },
        icon: 'platform',
        if: () => merchant.value.abilities?.update_google_integration,
        progress: getActiveGoogleIntegrationProgress(merchant.value),
      },
      // {
      //   icon: 'picture',
      //   title: t('store_migration.customize_theme'),
      //   route: { name: 'customize-theme' },
      //   if: () => storeSetuped
      // },
      // {
      //   icon: 'list',
      //   title: t('migration_tasks.title'),
      //   route: { name: 'migration-tasks' },
      //   if: () => merchant.value.abilities?.migrate
      // }
      {
        icon: 'document',
        title: t('menu.gads'),
        progress: merchant.value.google_campaign_progress,
        children: [
          {
            icon: 'document',
            route: { name: 'gads.campaigns', params: { id: merchant.value.id } },
            title: t('menu.campaign'),
            if: () => merchant.value.abilities?.update_google_campaign,
          },
          {
            icon: 'user',
            route: { name: 'gads.userLists', params: { id: merchant.value.id } },
            title: t('menu.googleUserLists'),
            if: () => authStore.user?.abilities?.create_google_user_list,
          }
        ],
      },
      {
        icon: 'DataAnalysis',
        title: t('menu.reporting'),
        route: { name: 'reporting', params: { id: merchant.value.id } },
        children: [
          {
            icon: 'DataLine',
            route: { name: 'reporting.data', params: { id: merchant.value.id } },
            title: t('menu.reporting_data'),
            if: () => merchant.value.abilities?.read_suggestion,
          },
          {
            icon: 'Finished',
            route: { name: 'reporting.suggestion', params: { id: merchant.value.id } },
            title: t('menu.reporting_suggestion'),
            if: () => merchant.value.abilities?.read_suggestion,
          }
        ]
      },
      {
        icon: 'MagicStick',
        title: t('menu.tamar_ai_accounts'),
        route: { name: 'tamar-ai-accounts.index', params: { id: merchant.value.id } },
        if: () => merchant.value.abilities?.create_tamar_ai_account,
      },
      {
        icon: 'Operation',
        title: t('menu.uiux_analytics_report'),
        route: { name: 'uiux-analytics-report.index', params: { id: merchant.value.id } },
        if: () => merchant.value.abilities?.create_tamar_ai_account,
      }
    ];
    return filterMenus(menuDef);
  });

  const getMenuAction = (action: typeof menus.value[number]): typeof menus.value[number] => {
    if (action.children?.length) {
      return getMenuAction(action.children[0]);
    }
    return action;
  };

  return {
    menus,
    getMenuAction
  };
};
