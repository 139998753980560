import { defineStore } from 'pinia';
import { type TamarAiAccountType } from '@/models/tamar-ai-account';
import { ref } from 'vue';
import { request } from '@/utils';

export const useTamarAiAccountStore = defineStore('tamar-ai-account', () => {
  const data = ref<TamarAiAccountType | null>(null);
  const id = ref<number | null>(null);

  function init(tamarAiAccountId: number) {
    id.value = tamarAiAccountId;
  }

  const isFetching = ref(false);
  async function fetchData() {
    isFetching.value = true;
    try {
      const res = await request.get<TamarAiAccountType>(`/tamar_ai_accounts/${id.value}`);
      data.value = res.data;
    } finally {
      isFetching.value = false;
    }
  }

  // 需要add_ga4_store权限
  const addGA4StoreLoading = ref(false);
  async function addGA4Store(body: { account_name: string; redirect_url: string; }) {
    addGA4StoreLoading.value = true;
    try {
      const { data } = await request.post<{ ga4_oauth_url: string }>(`/tamar_ai_accounts/${id.value}/add_ga4_store`, body);
      fetchData();
      return data;
    } finally {
      addGA4StoreLoading.value = false;
    }
  }

  // 需要add_ga4_store权限
  async function syncGA4StoreList() {
    await request.put<TamarAiAccountType>(`/tamar_ai_accounts/${id.value}/sync_ga4_store_list`);
    fetchData();
  }

  // 需要add_ga4_store权限
  const revokeGA4StoreLoading = ref(false);
  async function revokeGA4Store(body: { property_id: string }) {
    revokeGA4StoreLoading.value = true;
    try {
      await request.put(`/tamar_ai_accounts/${id.value}/revoke_ga4_store`, body);
      fetchData();
    } finally {
      revokeGA4StoreLoading.value = false;
    }
  }

  // 需要generate_uiux_analytics_report权限
  const generateUiuxAnalyticsReportLoading = ref(false);
  async function generateUiuxAnalyticsReport(propertyId: string) {
    generateUiuxAnalyticsReportLoading.value = true;
    try {
      const res = await request.post<TamarAiAccountType>(`/tamar_ai_accounts/${id.value}/generate_uiux_analytics_report`, { property_id: propertyId });
      data.value = res.data;
    } finally {
      generateUiuxAnalyticsReportLoading.value = false;
    }
  }

  return {
    data,
    init,
    fetchData,
    addGA4Store,
    syncGA4StoreList,
    revokeGA4Store,
    generateUiuxAnalyticsReport,
    isFetching,
    addGA4StoreLoading,
    revokeGA4StoreLoading,
    generateUiuxAnalyticsReportLoading,
  };
});
