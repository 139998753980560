import { defineStore } from 'pinia';
import { request } from '@/utils';
import { type MerchantType, getMerchantDefaultData } from '@/models/merchant';
import { ref } from 'vue';
import type { AxiosRequestConfig } from 'axios';

export const useMerchantStore = defineStore('merchant', () => {
  const data = ref<MerchantType>(getMerchantDefaultData());
  const id = ref<number | null>(null);

  function init(merchantId: number) {
    id.value = merchantId;
  }

  async function fetchData(config?: AxiosRequestConfig) {
    const res = await request.get<MerchantType>(`/merchants/${id.value}`, config);
    updateData(res.data);
  };

  async function updateData(newData: MerchantType) {
    Object.assign(data.value, newData);
  }

  return {
    id,
    data,
    fetchData,
    updateData,
    init,
  };
});

